.sidebar-container{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #222222;
    display: grid;
    align-items: center;
    top: 0;
    transition: 0.3s ease-in-out;
}

.opacity-on{
    opacity: 1;
    right: 0;
}
.opacity-off{
    opacity: 0;
    right: -100%;
}
.icon{
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: white;
    position: absolute;
    top: 2rem;
    right: 2.5rem;
}
.close-icon{
    color : whitesmoke;
}
.close-icon:hover{
    color : #CA955C;
}
.sidebar-menu{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 60px);
    text-align: center;
}
.sidebar-links{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out ;
}
.sidebar-links:hover{
    text-decoration: none;
    color:#CA955C;
}